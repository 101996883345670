/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

const spaceURL = `${process.env.REACT_APP_ASSETS_URL}/MainSpace-transformed.glb`;

type GLTFResult = GLTF & {
  nodes: {
    MainSpace: THREE.Mesh
    MainSpace2: THREE.Mesh
    CentrePiece: THREE.Mesh
  }
  materials: {
    MainSpace: THREE.MeshBasicMaterial
    MainSpace2: THREE.MeshBasicMaterial
    CentrePiece: THREE.MeshStandardMaterial
  }
}

export default function HotDeskSpace({ ...props }: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>(null)
  const { nodes, materials } = useGLTF(spaceURL, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh castShadow receiveShadow geometry={nodes.MainSpace.geometry} material={materials.MainSpace} position={[-0.88, -0.08, -1.93]} />
      <mesh castShadow receiveShadow geometry={nodes.MainSpace2.geometry} material={materials.MainSpace2} position={[4.36, -0.17, -4.05]} />
      <mesh castShadow receiveShadow geometry={nodes.CentrePiece.geometry} material={materials.CentrePiece} position={[-1.76, -1.3, -1.85]} rotation={[-Math.PI, 0.01, -Math.PI]} scale={1.49} />
    </group>
  )
}
