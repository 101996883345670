import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

// type GLTFResult = GLTF & {
//   nodes: {
//     Mesh_0: THREE.Mesh
//   }
//   materials: {
//     ['material_0.002']: THREE.MeshStandardMaterial
//   }
// }

type GLTFResult = GLTF & {
  nodes: {
    Mesh_0001: THREE.Mesh
  }
  materials: {
    'material_0.001': THREE.MeshBasicMaterial
  }
}

const spaceURL = `${process.env.REACT_APP_ASSETS_URL}/ark-boardroom--small.glb`;

export default function Boardroom({ ...props }: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>(null)
  const { nodes, materials } = useGLTF(spaceURL, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh castShadow receiveShadow geometry={nodes.Mesh_0001.geometry} material={materials['material_0.001']} />
    </group>
  )
}

  // <group ref={group} {...props} dispose={null}>
  //   <mesh castShadow receiveShadow geometry={nodes.Mesh_0.geometry} material={materials['material_0.002']} />
  // </group>
